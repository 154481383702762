import { AuthHeader, axiosInstance, setHeader } from "../utility/AxoisConfig";


let endpoint='api/playoff/'
export const playoffdetails= async (id,product=false,products=[])=>{
   const res = await axiosInstance.get(`${endpoint}${id}`,{params:{product:product,products:products}}).catch( (error) =>error)
    return res

}



export const playoffListdetails= async (id)=>{
	const res = await axiosInstance.get(`api/playoff-details/${id}/`).catch( (error) =>error)
	 return res
 
 }
 export const getGroupProducts= async (group,page=1)=>{
	const res = await axiosInstance.get(`api/group/${group}/products`,{params:{page_size:10,page:page}});
	return res
 
 }

export const getplayoffs= async (token,page=1)=>{
	const res = await axiosInstance.get(endpoint,{params:{page_size:5,page:page}});
	 // console.log(res);
	 
	 return res
 
 }

export const UpdatePlayOffView = async (data,token) => {
	let headers = setHeader(token)
	let res = await axiosInstance.post(`feed/playoff_views_update/`, data, { headers: headers })
	return res
};
export const UpdatePlayOffShare = async (data,token) => {
	let headers = setHeader(token)
	let res = await axiosInstance.post(`feed/playoff_share_update/`, data, { headers: headers })
	return res
};


export const getCurationsbyUser= async (token,id,page=1)=>{
	let headers = setHeader(token)
	let res = await axiosInstance.get(`api/playoff/curation/${id}`, { headers: headers, params:{page_size:5,page:page} })
	return res
}

export const getCurationsbyOthers= async (token,id,page=1)=>{
	let headers = setHeader(token)
	let res = await axiosInstance.get(`api/playoff/curation/others/${id}`, { headers: headers,params:{page_size:5,page:page} })
	return res
}

export const getAllCurationsbyPlayOff= async (token,id,page=1)=>{
	let headers = setHeader(token)
	let res = await axiosInstance.get(`api/playoff/all/${id}`, {headers, params:{page_size:5,page:page}})
	return res
}

export const getAllCurationsbyCategory= async (token,id,page=1)=>{
	let headers = setHeader(token)
	let res = await axiosInstance.get(`api/category/${id}/curation`, { headers, params:{page_size:5,page:page}})
	return res
}