import {
	Avatar,
	Badge,
	Box,
	Button,
	Grid,
	IconButton,
	Stack,
	Tooltip,
	Typography,
	keyframes,
} from "@mui/material";
import React, { Fragment, useContext, useEffect, useRef } from "react";
import { useState } from "react";
import ImgDefault from "assets/images/Rectangle 3.png";
import HeartOutlinded from "assets/icon/heart-outline.png";
import Heartfilled from "assets/icon/heart-filled.png";
import CollageIcon from "assets/icon/collageicon.png";
import { CurationDrawer } from "./CurationDrawer";
import {
	CreateProductFavourite,
	CreateProductView,
	UpdateProductFavourite,
	UpdateProductView,
} from "../../service/products";
import Lottie from "react-lottie";
import animationData from "assets/animation/curations_final.json";
import { ScrollContext } from "../../context/ScrollContext";

export const ProductCard = ({
	product,
	curation = null,
	handleViews,
	session,
	status,
	setAuthOpen,
	setOpen,
	setCurationsCount,
	handleFavorite = null,
	showTooltip = false,
}) => {
	const options = {
		animationData: animationData,
		loop: true,
	};

	const myKeyframe = keyframes`
	0% { background-image:linear-gradient(270deg, #7E27F1 2.94%, #FC8FE0 100%) }
	25% { background-image:linear-gradient(360deg, #7E27F1 0%, #3AB6EB 100%)}
	50% { background-image:linear-gradient(90deg, #7E27F1 2.94%, #FC8FE0 100%)}
	100% { background-image:linear-gradient(270deg, #7E27F1 2.94%, #FC8FE0 100%) }
    `;

	const { updateTrayItems } = useContext(ScrollContext);

	const [favorites, setFavorites] = useState([]);

	useEffect(() => {
		setFavorites(product.product_favourite);
	}, [product]);

	const handleCollageClick = () => {
		let { curation_count } = product;
		setCurationsCount(curation_count);
		updateTrayItems(curation_count);
		setOpen(true);
	};

	const handleFavoriteClick = async () => {
		if (handleFavorite != null) {
			let { product_favourite } = product;
			handleFavorite(product_favourite[0].id);
			return;
		}
		if (status === "authenticated") {
			let { product_favourite } = product;
			let user = session.user;
			if (product_favourite.length) {
				let pf = favorites[0];
				pf.favourite = false;
				let res = await UpdateProductFavourite(pf, session);

				product.product_favourite = product.product_favourite.filter(
					(product_favourite) => pf.id !== product_favourite.id
				);
				setFavorites(
					product.product_favourite.filter(
						(product_favourite) => pf.id !== product_favourite.id
					)
				);
				// updateProducts(product);
			} else {
				let res = await CreateProductFavourite(
					{
						product: product.id,
						faved_by: user.id,
						m_list: curation?.id,
					},
					session
				);

				product.product_favourite = [res.data];
				setFavorites([res.data]);
				// updateProducts(product);
			}
		} else {
			setAuthOpen(true);
		}
	};

	// const handleProductView = async () => {
	// 	window.open(product.url, "_blank", "noreferrer");
	// 	let { product_views } = product;

	// 	if (product_views.length) {
	// 		let index=-1
	// 		if(curation!=null){
	// 			index=product_views.findIndex(({m_list})=>m_list=curation.id)
	// 		}
	// 		product_view=index=-1?product_views[0]:

	// 		product_view.views += 1;
	// 		// let res = await UpdateProductView(product_view);
	// 		product.product_views[0] = res.data;
	// 		// updateProducts(product);
	// 		// window.open(product.url, "_blank", "noreferrer");
	// 	} else {
	// 		let input_data = {
	// 			product: product.id,
	// 			m_list: curation?.id,
	// 		};
	// 		if (isAuthenticated) {
	// 			let user = session.user;
	// 			input_data.viewed_by = user.id;
	// 		} else {
	// 			let currentUser = getAuth().currentUser;
	// 			input_data.uid = currentUser?.uid;
	// 		}
	// 		// let res = await CreateProductView(input_data);
	// 		product.product_views = [res.data];
	// 		// updateProducts(product);
	// 		// window.open(product.url, "_blank", "noreferrer");
	// 	}
	// };

	// console.log(product.product_favourite);

	return (
		<Fragment>
			<Grid item xs={6} sx={{ flexShrink: "0,", position: "relative" }}>
				{showTooltip == true ? (
					<Tooltip
						open={true}
						title={
							<Typography
								variant="body2"
								color={"white"}
								sx={{ padding: 0.5 }}
							>
								Tap to Shop
							</Typography>
						}
						arrow
						PopperProps={{
							sx: {
								zIndex: 0,
								"& .MuiTooltip-tooltip": {
									borderRadius: "10px",
									backgroundColor: "#3AB6EB",
								},
								"& .MuiTooltip-arrow": {
									color: "#3AB6EB",
								},
							},
						}}
						placement="top"
					>
						<Box
							sx={{
								position: "absolute",
								left: "35%",
								top: "12%",
								width: "35px",
							}}
						></Box>
					</Tooltip>
				) : null}
				<Box
					onClick={() => {
						handleViews(product);
					}}
					sx={{
						border: 1,
						backgroundColor: "white",
						borderColor: "grayColor.main",
						borderRadius: "8px",
					}}
				>
					<Avatar
						variant="square"
						alt={product?.name}
						src={product?.image?.file}
						sx={{
							width: "100%",
							height: 216,
							borderRadius: "8px",
						}}
					/>

					<Box m={1}>
						<Stack direction="row" sx={{ mt: "8px" }}>
							{product.discount_price !== 0 ? (
								<>
									<Typography variant="body2">
										&#8377;{product.discount_price}
									</Typography>

									{product.original_price -
										product.discount_price >
									0 ? (
										<>
											<Typography
												variant="smallText2"
												sx={{
													marginX: "3px",
													textDecoration:
														"line-through",
													display: "flex",
													alignItems: "center",
													opacity: 0.5,
												}}
											>
												&#8377;{product.original_price}
											</Typography>
											<Typography
												variant="smallText2"
												color={"primary"}
												sx={{
													display: "flex",
													alignItems: "center",
												}}
											>
												{Math.round(
													(100 *
														(product.original_price -
															product.discount_price)) /
														product.original_price
												)}
												% OFF
											</Typography>
										</>
									) : (
										""
									)}
								</>
							) : (
								<Typography variant="body2">
									&#8377;{product.original_price}
								</Typography>
							)}
						</Stack>
						<Box sx={{ lineHeight: "16px", mt: "5px" }}>
							<Typography
								variant="smallText"
								sx={{
									opacity: 0.65,
									display: "-webkit-box",
									overflow: "hidden",
									WebkitBoxOrient: "vertical",
									WebkitLineClamp: 2,
									height: "32px",
								}}
							>
								{product.name}
							</Typography>
						</Box>
						<Box sx={{ marginY: 0 }}>
							<Typography variant="smallText">
								{product.seller?.name}
							</Typography>
						</Box>
						<Box mt={1}>
							<Button
								// onClick={() => {
								// 	handleViews(product);
								// }}
								disableElevation
								fullWidth
								// href={product.url}
								// target="_blank"
								color="shopNowButton"
								variant="contained"
								sx={{
									borderRadius: 2,
									height: "40px",
									backgroundColor: "primary.main",
									color: "white",
									backgroundColor: "primary.main",
									color: "white",
								}}
							>
								Visit store
							</Button>
						</Box>
					</Box>
				</Box>
				<Stack
					spacing={1}
					sx={{
						position: "absolute",
						margin: 1,
						marginTop: 3.5,
						marginRight: 1.5,
						top: 0,
						right: 0,
					}}
				>
					<IconButton
						disableRipple
						disableFocusRipple
						sx={{
							backgroundColor: "white",
							height: 28,
							width: 28,
						}}
						onClick={() => {
							handleFavoriteClick();
						}}
						// ref={clickOnFavButton}
					>
						<img
							src={
								favorites?.length ? Heartfilled : HeartOutlinded
							}
							alt="favorite"
							// style={{ height: "12px" }}
						></img>
					</IconButton>
					{product.is_collage_icon_show ? (
						<Box>
							<Badge
								badgeContent={product.curation_count?.count}
								sx={{
									"& .MuiBadge-badge": {
										fontSize: 9,
										zIndex: 0,
										// borderRadius:"50%",
										height: 15,
										// minWidth: 15,
										bottom: "5px",
										right: "4px",
									},
								}}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "right",
								}}
								color="primary"
							>
								<Box onClick={handleCollageClick}>
									<Lottie
										options={options}
										isClickToPauseDisabled={true}

										// height={100}
									></Lottie>
								</Box>
								{/* <IconButton
									disableRipple
									disableFocusRipple
									sx={{
										display: product.is_collage_icon_show
											? ""
											: "none",
										height: 28,
										width: 28,
										backgroundColor: "white",
										backgroundImage:
											"linear-gradient(270deg, #7E27F1 2.94%, #FC8FE0 100%)",
										backgroundOrigin: "border-box",
										boxShadow: "inset 0 1000px white",
										animation: `${myKeyframe} 2s ease-in-out infinite`,
										border: "2px solid transparent",
									}}
									onClick={handleCollageClick}
									// ref={clickOnFavButton}
								>
									<img src={CollageIcon} alt="favorite"></img>
								</IconButton> */}
							</Badge>
						</Box>
					) : null}
				</Stack>
			</Grid>
		</Fragment>
	);
};
