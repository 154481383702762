import { Delete, FavoriteBorder } from "@mui/icons-material";
import {
	Avatar,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CardMedia,
	Stack,
	Typography,
} from "@mui/material";
import HeartOutlinded from "assets/icon/heart-outline.png";
import Heartfilled from "assets/icon/heart-filled.png";
import LinesEllipsis from "react-lines-ellipsis";
import { useState } from "react";
import { useEffect } from "react";
import { UpdateListFavourite } from "../../service/Curation";
import { CreateListFavourite } from "../../service/Curation";

export const CurationCard = ({
	curation,
	handleClick,
	session,
	setAuthOpen,
}) => {
	const [favourites, setFavourites] = useState([]);
	const [favourited, setFavourited] = useState(
		curation.user_list_favourites != null
	);
	const [favCount, setFavCount] = useState(curation.favourites_count);
	useEffect(() => {
		setFavourites(
			curation.user_list_favourites ? [curation.user_list_favourites] : []
		)
	}, [curation]);
	const handleFavClick = async (e) => {
		e.stopPropagation();
		if (session === null) {
			setAuthOpen(true);
		} else {
			let list_favourites = favourites;
			let user = session.user;
			if (list_favourites.length) {
				let pf = list_favourites[0];
				pf.favourite = false;
				let res = await UpdateListFavourite(pf, session);

				list_favourites = favourites.filter(
					(list_favourites) => pf.id !== list_favourites.id
				);
				let cur = { ...curation };
				setFavourites([]);
				cur.user_list_favourites = [];
				cur.favourites_count -= 1;
				setFavourited(false);
				setFavCount((favCount) => (favCount -= 1));
				// setCuration(cur);
			} else {
				let res = await CreateListFavourite(
					{
						faved_by: user.id,
						m_list: curation.id,
					},
					session
				);
				let cur = { ...curation };
				cur.user_list_favourites = [res.data];
				cur.favourites_count += 1;
				setFavourites([res.data]);
				setFavCount((favCount) => (favCount += 1));
				setFavourited(true);
				// setCuration(cur);
				// updateProducts(product);
			}
		}
	};
	return (
		<Card
			sx={{
				p: 1.5,
				borderRadius: 3,
				border: "1px solid #E9E7ED",
				boxShadow: "none",
			}}
			onClick={() => {
				handleClick(curation.slug);
			}}
		>
			<CardHeader
				sx={{
					pt: 0,
					px: 0,
					pb: 1,
					[`& .MuiCardHeader-avatar`]: {
						marginRight: 1,
					},
				}}
				avatar={
					<Avatar
						sx={{
							width: "22px",
							height: "22px",
						}}
						src={curation.owner.image?.file}
						alt={curation.owner.name}
					></Avatar>
				}
				title={curation.owner.name}
				titleTypographyProps={{
					variant: "smallText",
				}}
			/>
			<CardMedia
				component="img"
				height="auto"
				// width="138"
				sx={{ borderRadius: "6px" }}
				image={curation.image.file}
				alt={curation.title}
			/>
			<CardContent sx={{ px: 0, py: 1 }}>
				<Typography variant="smallText" color="rgba(0, 0, 0, 0.6)">
					<LinesEllipsis
						text={curation.title}
						maxLine="1"
						ellipsis="..."
						trimRight
						basedOn="letters"
					/>
				</Typography>
			</CardContent>
			<CardActions sx={{ p: 0 }}>
				<Stack direction="row" alignItems="center" gap={0.5}>
					<img
						onClick={handleFavClick}
						src={favourited ? Heartfilled : HeartOutlinded}
						alt="favorite"
						// style={{ height: "12px" }}
					></img>

					<Typography variant="smallText">
						{favCount} favourites
					</Typography>
				</Stack>
			</CardActions>
		</Card>
	);
};
