import { AuthHeader, axiosInstance, setHeader } from "../utility/AxoisConfig";

export const CreateCuration = async (data,token) => {
	let headers = setHeader(token)
	let res = await axiosInstance.post("/api/communitylist/", data, {
		headers: headers,
	});
	return res;
};

let endpoint = "api/communitylist/";

export const curationDetails = async (id,session) => {
	let headers = setHeader(session);
	const res = await axiosInstance.get(`${endpoint}${id}`, {
		headers: headers,
	}).catch( (error) =>error);
	// console.log(res);
	return res;
};

export const curationDetailsWithoutUser = async (id,uid) => {
	let headers = AuthHeader();
	const res = await axiosInstance.get(`api/curation/${id}`,{params:{uid:uid},
		headers: headers,
	});
	// console.log(res);
	return res;
};

export const getProductsForCuration = async (ids,uuid,pk,token) => {
	let headers = setHeader(token);
	try {
		const res = await axiosInstance.post(
			`api/curation-products/${pk}`,
			{ ids: ids,uuid:uuid },
			{ headers: headers }
		);
        console.log(res);
		return {
			status: true,
			products: res.data.products,
			success:""
		};
	} catch (error) {
		console.log("====================================");
		console.log(error);
		console.log("====================================");
		return {
			status: false,
			products: [],
			error: "Something went wrong! Try refresh the page",
		};
	}
};


export const getCategoryDetails = async (id) => {
	
	let res = await axiosInstance.get(`/api/categories/${id}`);
	return res;
};


export const UpdateListFavourite = async (data,token) => {
	let headers=setHeader(token)
	let res = await axiosInstance.put(`feed/list-fav/${data.id}/`, data, { headers: headers })
	return res
};

export const CreateListFavourite = async (data,token) => {
	let headers=setHeader(token)
	let res= await axiosInstance.post("feed/list-fav/", data, { headers: headers })
	return res
		
	// return ;
};


export const CreateListView = async (data) => {
	let headers = setHeader(token)
	let res= await axiosInstance.post("feed/list-view/", data, { headers: headers })
	return res
		
	// return ;
};
export const UpdateListView = async (data,token) => {
	let headers = setHeader(token)
	let res = await axiosInstance.post(`feed/list_views_update/`, data, { headers: headers })
	return res
};

export const getFavouriteDetails = async (data,token) => {
	let headers = setHeader(token);
	const res = await axiosInstance.get(`api/curation/${data.curation}/feed`,{params:data,
		headers: headers,
	});
	// console.log(res);
	return res;
};


export const UpdateCurationShare = async (data,token) => {
	let headers = setHeader(token)
	let res = await axiosInstance.post(`feed/list_share_update/`, data, { headers: headers })
	return res
};

export const CreateRating=async (data,token)=>{
	let headers = setHeader(token);
	let res = await axiosInstance.post(`api/list-rating/`, data, { headers: headers })
	return res
}