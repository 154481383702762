import { AuthHeader, axiosInstance, setHeader } from "../utility/AxoisConfig";

export const UpdateProductFavourite = async (data,token) => {
	let headers = setHeader(token)
	let res = await axiosInstance.put(`feed/product-fav/${data.id}/`, data, { headers: headers })
	return res
};

export const CreateProductFavourite = async (data,token) => {
	let headers = setHeader(token)
	let res= await axiosInstance.post("feed/product-fav/", data, { headers: headers })
	return res
		
	// return ;
};


export const CreateProductView = async (data) => {
	let headers = AuthHeader();
	let res= await axiosInstance.post("feed/product_views_update/", data, { headers: headers })
	return res
		
	// return ;
};
export const UpdateProductView = async (data) => {
	let headers = AuthHeader();
	// let res = await axiosInstance.put(`feed/product-view/${data.id}/`, data, { headers: headers })
	let res= await axiosInstance.post("feed/product_views_update/", data, { headers: headers })
	return res
};

export const getProductsCurations = async (id,page,session)=>{
	let headers=setHeader(session)
	let res = await axiosInstance.get(`api/product-curation/${id}/`, {params:{page:page,page_size:6}, headers: headers })
	return res
}