import {
	Box,
	Button,
	Card,
	CardContent,
	Grid,
	Stack,
	SwipeableDrawer,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import _ from "lodash";
import { CurationCard } from "./CurationCard";
import {
	Fragment,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { getProductsCurations } from "../../service/products";
import emptyCard from "assets/icon/Curly-hair-bro.png";
import circleIcon from "assets/icon/circle_on_your.png";
import Image from "next/image";
import { useRouter } from "next/router";
import InfiniteScroll from "react-infinite-scroller";
import { PaginationLoader } from "../LoadingSpinner/PaginationLoader";
import { updateScroll } from "../../hooks/ScrollHandler";
import { ScrollRestorationContext } from "../../context/ScrollRestorationContext";
import { UpdatePlayOffShare } from "../../service/PlayoffService";
import { getAuth } from "firebase/auth";
import { ScrollContext } from "../../context/ScrollContext";

export const CurationDrawer = ({
	session,
	status,
	setOpen,
	open,
	curationsCount,
	otherCurations,
	setCurationsCount,
	setAuthOpen,
}) => {
	const [id, setId] = useState(null);
	const [playoff, setPlayoff] = useState(
		curationsCount != null ? curationsCount.latest_playoff : null
	);
	const [product, setProduct] = useState(null);
	const [curations, setCurations] = useState([]);
	const [fetching, setFetching] = useState(false);
	const [loading, setLoading] = useState(true);
	const [currPage, setCurrPage] = useState(1);
	const [hasMore, setHasMore] = useState(true);
	const { updateTrayItems } = useContext(ScrollContext);
	const router = useRouter();
	const scrollContainerRef = useRef(null);

	const handleCurationCardClick = async (slug) => {
		setOpen(false);
		setCurationsCount({
			...curationsCount,
			scrollTo: scrollContainerRef.current.scrollTop,
		});
		updateTrayItems({
			...curationsCount,
			scrollTo: scrollContainerRef.current.scrollTop,
			excuted: false,
		});
		router.push(`/curation/${slug}`);
	};
	const getScrollParent = (element) => {
		if (!element) return null;
		const style = window.getComputedStyle(element);
		const overflowRegex = /(auto|scroll)/;

		if (
			overflowRegex.test(style.overflow) ||
			overflowRegex.test(style.overflowX) ||
			overflowRegex.test(style.overflowY)
		) {
			return element;
		}

		return getScrollParent(element.parentNode);
	};

	const [count, setCount] = useState(curationsCount?.count);
	const fetchItems = useCallback(async () => {
		if (fetching || id === null) {
			return;
		}
		if (
			curationsCount.drawerCurations?.length &&
			curationsCount.excuted != true
		) {
			setCurations(curationsCount.drawerCurations);
			setCurrPage(curationsCount.currPage);
			setHasMore(curationsCount.hasMore);
			setCurationsCount({ ...curationsCount, excuted: true });
			if (curationsCount.scrollTo) {
				let interval = setInterval(() => {
					if (scrollContainerRef.current) {
						clearInterval(interval);
						scrollContainerRef.current.scroll({
							top: curationsCount.scrollTo,
							behavior: "auto",
						});
					}
				}, 500);
			}
			// scrollContainerRef.current.scrollTo(0,curationsCount.scrollTo)
			// setFetching(true);
			return;
		}
		setFetching(true);
		try {
			let res = await getProductsCurations(id, currPage, session);
			let new_curations = res.data.curations;

			// setCurations(curations.results);
			// setCount(res.data.count);
			const next = new_curations.has_next;
			setPlayoff(res.data.latest_playoff)
			setProduct(res.data.product);
			setCurations([...curations, ...new_curations.results]);

			if (next) {
				setCurrPage(currPage + 1);
			}
			setHasMore(next);
			setCurationsCount({
				...curationsCount,
				currPage: currPage + 1,
				hasMore: next,
				drawerCurations: [...curations, ...new_curations.results],
			});
			updateTrayItems({
				...curationsCount,
				currPage: currPage + 1,
				hasMore: next,
				drawerCurations: [...curations, ...new_curations.results],
			});
		} catch (error) {
			setHasMore(false);
		} finally {
			setFetching(false);
		}
	}, [curations, fetching, hasMore, id]);
	const getData = async () => {
		if(curationsCount.id==!null){
		let res = await getProductsCurations(curationsCount.id, 1, session);
		// setCurations([]);
		console.log('====================================');
		console.log(res.data);
		console.log('====================================');
		setProduct(res.data.product);
		setCount(res.data.count);
		setLoading(false);
		setPlayoff(res.data.latest_playoff);
		}else{
			setLoading(false);
		}
	};
	const handleCurateButton = () => {
		if (!session) {
			setAuthOpen(true);
			return;
		} else {
			localStorage.setItem("playoff_id", playoff.id);
			localStorage.setItem(
				"products",
				JSON.stringify([{ id: product.id, selected: true }])
			);
			router.push(`/playoff/${playoff.slug}/products`);
		}
	};

	const handleAskYourTribeButton = async () => {
		let url = product.image.file.replace(
			"https://d2h50zujfkj84t.cloudfront.net",
			"https://grape-images-bucket.s3.ap-south-1.amazonaws.com"
		);
		const blob = await fetch(url).then((r) => r.blob());
		const shareddata = {
			title: product.name,
			text: `Hey, discovered this product on Grape with various styling options by awesome curators. Would love to know how you'd style it!`,
			url: playoff.deeplink,
			files: [
				new File([blob], "file.png", {
					type: blob.type,
				}),
			],
		};
		if (navigator.canShare && navigator.canShare(shareddata)) {
			let data = { playoff: playoff.id };
			if (!session) {
				let auth = getAuth();
				let user = auth.currentUser;
				data.uid = user.uid;
			} else {
				let user = session.user;
				data = { playoff: playoff.id, shared_by: user.id };
			}
			let list = await UpdatePlayOffShare(data, session);
			navigator
				.share(shareddata)
				.then(() => console.log("Successful share"))
				.catch((error) => console.log("Error sharing", error));
		} else {
			console.log("====================================");
			console.log("not supporting");
			console.log("====================================");
		}

		// localStorage.setItem("playoff_id", playoff.id);
		// localStorage.setItem(
		// 	"products",
		// 	JSON.stringify([{ id: product, selected: true }])
		// );
		// router.push(`/playoff/${playoff.slug}/products`);
	};

	useEffect(() => {
		if (curationsCount !== null) {
			setId(curationsCount.id);
			setCurations([]);
			setCurrPage(1);
			setHasMore(true);
			setFetching(false);
			setProduct(null);
			setCount(curationsCount.count);
			// setLoading(false)
			getData();
			
			// setCurations([]);
		}
	}, [open, session, status]);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	// const [open, setOpen] = useState(true);
	const containerRef = useRef(null);

	let mobileCss = !isMobile
		? {
				width: 480,
				left: "50%",
				transform: "translate(-50%)",
		  }
		: { width: "100%" };
	return (
		<SwipeableDrawer
			sx={{ transition: "transform 300ms ease-in-out", ...mobileCss }}
			transitionDuration={{
				enter: 300, // Adjust the value as needed
				exit: 195, // Adjust the value as needed
			}}
			PaperProps={{
				square: false,
				sx: {
					borderRadius: "24px 24px 0px 0px",
					height: "calc(100% - 90px)",
					overflow: "auto",
					// ...mobileCss
				},
			}}
			anchor={"bottom"}
			open={open}
			onClose={() => {
				setId(null);
				setOpen(false);
				setLoading(true);
			}}
			onOpen={() => {}}
			slotProps={{
				backdrop: { style: { backgroundColor: "rgba(0, 0, 0, 0.7)" } },
			}}
		>
			<Box style={{ height: "100%", overflow: "hidden" }}>
				<Box
					sx={{
						width: 48,
						height: 6,
						backgroundColor: "rgba(0, 0, 0, 0.2)",
						borderRadius: 3,
						marginX: "auto",
						// position: "absolute",
						marginTop: 1.5,
						marginBottom: 1.75,
						// left: "calc(50% - 15px)",
					}}
				></Box>
				{loading ? (
					<PaginationLoader
						key={"loaderrr-1"}
						shimmertype={"product_card"}
						pageNumber={1}
						mX={0}
						isListingScreen={false}
					></PaginationLoader>
				) : (
					<Box
						p={2.5}
						ref={scrollContainerRef}
						sx={{ height: "calc(100%)", overflow: "auto" }}
					>
						{id !== null && count > 0 ? (
							<Fragment>
								<Box>
									<Typography
										variant="h2_new"
										component={"h2"}
										sx={{
											textTransform: "capitalize",
										}}
									>
										From the Grapevine
									</Typography>
									<Typography
										component={"p"}
										variant="body2_light"
										pt={1}
									>
										{count} ways to style this product from
										our community
									</Typography>
								</Box>

								<InfiniteScroll
									pageStart={currPage}
									loadMore={fetchItems}
									hasMore={hasMore}
									useWindow={false}
									getScrollParent={() =>
										getScrollParent(
											scrollContainerRef.current
										)
									}
									loader={
										<PaginationLoader
											key={"loaderrr-1"}
											shimmertype={"product_card"}
											pageNumber={1}
											mX={0}
											isListingScreen={false}
										></PaginationLoader>
									}
								>
									<Grid
										container
										pt={2.5}
										spacing={1.5}
										mb={4}
									>
										{curations.map((curation, i) => {
											if (
												i == 3 ||
												(curations.length < 4 &&
													i == curations.length - 1)
											) {
												return (
													<Fragment key={i}>
														<Grid
															key={i}
															item
															xs={6}
														>
															<CurationCard
																session={
																	session
																}
																setAuthOpen={
																	setAuthOpen
																}
																curation={
																	curation
																}
																handleClick={
																	handleCurationCardClick
																}
															/>
														</Grid>
														<Grid item xs={12}>
															<Card
																sx={{
																	// mt: 4,
																	borderRadius: 2,
																	border: "1px solid #E9E7ED",
																	boxShadow:
																		"0px 4px 10px -6px rgba(0, 0, 0, 0.12)",
																}}
															>
																<CardContent
																	sx={{
																		textAlign:
																			"center",
																		py: 4,
																	}}
																>
																	<Typography
																		variant="h2_new"
																		component={
																			"h2"
																		}
																		sx={{
																			textAlign:
																				"center",
																			textTransform:
																				"capitalize",
																		}}
																	>
																		Add{" "}
																		<Typography
																			variant="h2_new"
																			sx={{
																				position:
																					"relative",
																			}}
																		>
																			<img
																				style={{
																					position:
																						"absolute",
																					left: "-7px",
																					top: "-5px",
																				}}
																				src={
																					circleIcon
																				}
																			></img>
																			your
																		</Typography>{" "}
																		take to
																		the
																		grapevine
																	</Typography>
																	<Stack
																		marginTop={
																			4
																		}
																		paddingBottom={
																			0
																		}
																		direction={
																			"row"
																		}
																		justifyContent="space-around"
																		alignItems="center"
																		spacing={
																			1
																		}
																	>
																		<Button
																			sx={{
																				padding:
																					"12px 0px",
																			}}
																			variant="contained"
																			fullWidth
																			// size={"large"}
																			onClick={
																				handleCurateButton
																			}
																		>
																			Curate
																			Yourself
																		</Button>
																		<Button
																			onClick={
																				handleAskYourTribeButton
																			}
																			variant="outlined"
																			fullWidth
																			sx={{
																				padding:
																					"12px 0px",
																			}}
																		>
																			Ask
																			your
																			tribe
																		</Button>
																	</Stack>
																</CardContent>
															</Card>
														</Grid>
													</Fragment>
												);
											}
											return (
												<Grid key={i} item xs={6}>
													<CurationCard
														session={session}
														setAuthOpen={
															setAuthOpen
														}
														curation={curation}
														handleClick={
															handleCurationCardClick
														}
													/>
												</Grid>
											);
										})}
									</Grid>
								</InfiniteScroll>
							</Fragment>
						) : (
							<Box sx={{ textAlign: "center" }}>
								<img src={emptyCard} alt="empty card"></img>
								<Typography variant="h1" component={"h1"}>
									Be the first one to Curate
								</Typography>
								<Card
									sx={{
										mt: 4,
										borderRadius: 2,
										border: "1px solid #E9E7ED",
										boxShadow:
											"0px 4px 10px -6px rgba(0, 0, 0, 0.12)",
									}}
								>
									<CardContent
										sx={{
											textAlign: "center",
											py: 4,
										}}
									>
										<Typography
											variant="h2_new"
											component={"h2"}
											sx={{
												textAlign: "center",
												textTransform: "capitalize",
											}}
										>
											Add{" "}
											<span sx={{ position: "relative" }}>
												your
											</span>{" "}
											take to the grapevine
										</Typography>
										<Stack
											marginTop={4}
											direction={"row"}
											justifyContent="space-around"
											alignItems="center"
											spacing={1}
										>
											<Button
												sx={{ padding: "12px 0px" }}
												variant="contained"
												fullWidth
												// size={"large"}
												onClick={handleCurateButton}
											>
												Curate Yourself
											</Button>
											<Button
												onClick={
													handleAskYourTribeButton
												}
												variant="outlined"
												fullWidth
												sx={{ padding: "12px 0px" }}
											>
												Ask your tribe
											</Button>
										</Stack>
									</CardContent>
								</Card>
							</Box>
						)}
					</Box>
				)}
			</Box>
		</SwipeableDrawer>
	);
};
